.chapterContainer{
  width: 100%;
  height: 100%;
  .tableList{
    
  }
  .editor{
    border: 1px solid #999;
  }
  .addWrapper{
    display: block;
    width: 100px;
    margin-bottom: 10px;
  }
}
.completed-row {
  background-color: #0ae813;
}