.chapterContainer{
  width: 100%;
  height: 100%;
  background: rgb(239,238,247);
  .textLeft{
    text-align: left;
    font-size:25px;
    background: #fff;
    padding:20px;
  }
  .bottom{
    margin-bottom:50px;
    margin-top:50px;
  }
  .Title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:20px;
    .Tip{
      color:#666;
      font-size:15px;
    }
    .Tip1{
      color: #e8d9d9;
      font-size:15px;
    }
    .context{
      text-align:left;
      .number{
        font-size:35px;
      }
      .tips{
        font-size:15px;
        color: #f1e3e3;
      }
    }
  }
  .leftNr{
    color:#fff;
    background: rgb(49,124,249);
    padding:20px;
    height: 76vh;
  }
  .rightNr{
    color:#000;
    background: #fff;
    padding:20px;
    .gutter-row{
      //border: 1px #666 solid;
      box-shadow: 5px 5px 10px rgba(204, 204, 204, 0.54);
      padding:20px;
      text-align:left;
      font-size:15px;
      border-radius: 5px;
      .Number{
        font-size:35px;
        color:rgb(93,138,184);
      }
      .tip{
        color:#666;
        font-size:10px;
      }
    }
    .gutter-row:hover{
      border: 1px blue solid;
      box-shadow: 1px 1px 1px blue;
    }
  }
}