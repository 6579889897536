.userContainer{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .left,.right {
    width:50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .right{
    width: 300px;
    margin-left: 32px;
  }
  .left {
    border-right: 1px solid #999;
  }
}