.blogDetail{
  width: 100vw;
  overflow-x: hidden;
  padding-bottom: 50px;
  .main{
    box-sizing: border-box;
    padding: 32px;
    text-align: left;
    box-shadow: 0 0 10px #000;
    border-radius: 10px;
    margin: 0 32px;
    max-width: 100%;
  }
  .blogAppHref{
    background: rgb(248, 248, 248);
    border-radius: 4px;
    padding: 16px;
    margin-top: 50px;
    a{
      width: 100%;
      word-wrap:break-word
    }
  }
  .blogContent{
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    text-align: left;
    max-width: 500px;
    margin: auto;
    border-left: 1px solid rgb(153, 153, 153);
    border-right: 1px solid rgb(153, 153, 153);
  }
  .blogTitle{
    font-weight: bold;
    font-size: 28px;
    border-bottom: 1px solid rgb(240,240,240);
    text-align: center;
  }
}

@media screen and (max-width:600px) {
  .main{
    margin: 0 !important;
    box-shadow: none !important;
  }
  .blogTitle{
    text-align: center;
    font-size: 20px !important;
  }
  .blogAppHref{
    a{
      font-size: 12px;
    }
  }
}