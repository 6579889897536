.right{
  width:300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 10px #000;
  border-radius: 10px;
  overflow: hidden;
  .contentList{
    width: 100%;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .item{
      width: 100%;
      margin-bottom: 15px;
      cursor: pointer;
      &:hover{
      text-decoration: underline; 
        color: #1890ff;
      }
      span{
        padding:  0 5px ;
        font-weight: bold;
      }
    }
  }
  .top{
    width: 100%;
    height: 200px;
    background: url("../../components/chapterItem/bk_flower.png") repeat;
    background-color: rgb(24, 144, 255);
    position: relative;
    margin-bottom: 70px;
    .position{
      position: absolute;
      bottom: -50px;
      left: calc(50% - 50px);
    }
  }
}