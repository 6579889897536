.blogContainer{
  width: 100%;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .rightArea{
    height: 100%;
    flex: 1;
    box-shadow: 0 0 10px #000;
    border-radius: 10px;
    overflow: auto;
  }
  .leftArea{
    margin-right: 32px;
    // height: 100%;
    .right{
      height: 100%;
      justify-content: flex-start;
      box-shadow: 0 0 10px #000;
      border-radius: 10px;
      overflow: hidden;
      p{
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width:600px) {
  .blogContainer{
    flex-direction: column;
    padding: 16px !important;
    padding-bottom: 50px;
    overflow: auto;
  }
  .leftArea{
    margin-right: 0 !important;
    margin-bottom: 20px;
    .top{
      height: 100px;
    }
  }
  .rightArea{
    max-width: 100%;
    width: 100%;
    overflow: initial !important;
    .name {
      font-size: 16px !important;
    }
    .date{
      width: 0 !important;
      overflow: hidden;
    }
    .src{
      max-width: 50%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow:ellipsis;
    }
  }
}